@use '../../styles/_variables' as *;

.update-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #B7D4FF80;
  backdrop-filter: blur(50px);
  z-index: 100000;
  overflow: auto;

  .update-container {
    position: absolute;
    padding: 100px 0;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .update-modal_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 100%;
    padding: 0 20px;

    .update-modal_text {
      color: #EA7B07;
      margin-bottom: 40px;

      span {
        color: #043192;
      }

      @media (max-width: 320px) {
        font-size: 36px;
      }
      @media (max-width: 576px) {
        text-align: center;
      }
    }

    .update-modal_button {
      min-height: 84px;

      &.play-button {
        border: none;
        background:  linear-gradient(180deg, #FFBD00 0%, #E96506 100%);
        box-shadow: $btn-shadow;
        line-height: 72%;
        text-transform: uppercase;
        border-radius: 20px;
        padding:0 80px;
        color: #fff;
        font-family: "Paytone One", sans-serif;
        font-size: 32px;
        cursor: pointer;

        &:hover:enabled {
          box-shadow: $btn-shadow-hover;
          background:
                  url('../../assets/btn-hover.svg'),
                  linear-gradient(180deg, #e9d906 0%, #e96706 100%) 100%;
          background-size: 100% 100%;
        }

        &:active:enabled {
          box-shadow: $btn-shadow-pressed;
          background:
                  url('../../assets/btn-hover.svg'),
                  linear-gradient(180deg, #e9d906 0%, #e96706 100%) 100%;
          background-size: 100% 100%;
        }
      }

      @media (max-width: 376px) {
        width: 100%;
        &.play-button {
          padding: 0;
        }
      }
    }
  }
}