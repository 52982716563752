@import '../../styles/variables';

.language-dropdown {
    position: relative;

    .language {
        width: 69px;
        height: 45px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        background: url('../../assets/languages_icon.svg') no-repeat;

        .language-info {
            width: 66%;
            height: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            left: 10px;
            overflow: hidden;

            span {
                font-size: 20px;
                line-height: 20px;
                text-transform: uppercase;
                font-weight: 600;
                font-family: 'Archivo', sans-serif;
            }
        }

        .language-arrow {
            width: 16px;
            height: 12px;
            margin-left: 2px;
        }
    }

    .languages-box {
        width: 132px;
        height: 0; // Start with height 0
        top: 38px;
        left: -63px;
        z-index: 10;
        position: absolute;
        background: url('../../assets/languagesBox.svg') no-repeat;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-10px);
        overflow: hidden; // Ensure content is hidden when collapsed
        transition:
            opacity 0.3s ease,
            transform 0.3s ease,
            height 0.3s ease,
            visibility 0.3s ease;

        &.visible {
            opacity: 1;
            visibility: visible;
            height: 190px; // Expand to full height
            transform: translateY(0);

            @media (max-width: $mobile-lg) {
                height: 235px;
                transform: translate(
                    calc(
                        -130px + (-155 - 130) * ((100vw - 320px) / (1920 - 320))
                    ),
                    0
                );
            }

            @media (max-width: $mobile) {
                height: 382px;
            }

            @media (max-width: 360px) {
                transform: translate(
                    calc(
                        -116px + (-155 - 118) * ((100vw - 320px) / (1920 - 320))
                    ),
                    0
                );
            }

            @media (max-width: 330px) {
                transform: translate(-105px, 0);
            }
        }

        @media (max-width: $mobile-lg) {
            width: 178px;
            top: 55px;
            left: -107px;
            background: url('../../assets/languagesBox-578.svg') no-repeat;
        }

        @media (max-width: $mobile) {
            width: 291px;
            top: 55px;
            background: url('../../assets/languagesBox-414.svg') no-repeat;
        }

        ul {
            width: 132px;
            height: 190px;
            background: transparent;
            padding: 30px 20px 20px 20px;
            overflow: hidden;
            opacity: 0;
            transform: translateY(-10px);
            transition:
                opacity 0.4s ease 0.2s,
                transform 0.4s ease 0.2s; // Add a delay to match the box transition

            &.hidden {
                @media (max-width: $mobile-lg) {
                    li {
                        opacity: 0;
                        transform: translateY(-10px);
                        display: flex;
                        align-items: center;
                    }
                }
            }

            &.visible {
                opacity: 1;
                transform: translateY(0);

                @media (max-width: $mobile-lg) {
                    width: 178px;
                    height: 235px;
                    padding: 20px 30px 30px 30px;
                }

                @media (max-width: $mobile) {
                    width: 291px;
                    height: 382px;
                    padding: 40px 40px 30px 40px;
                }
            }

            li {
                height: 25px;
                font-size: 15px;
                line-height: 15px;
                font-weight: 400;
                font-family: 'Archivo', sans-serif;
                color: $main-text;
                border-bottom: 0.5px solid #e7e7e7;
                display: flex;
                align-items: center;

                @media (max-width: $mobile-lg) {
                    height: 32px;
                    font-size: 20px;
                    line-height: 20px;
                }

                @media (max-width: $mobile) {
                    height: 50px;
                    font-size: 33px;
                    line-height: 33px;
                }

                &:last-child {
                    border-bottom: none;
                }

                .selected {
                    text-decoration: underline;
                    font-weight: 600;
                }
            }
        }

        &_button {
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            cursor: pointer;
            color: $main-text;

            &:hover:enabled {
                font-weight: 600;
            }

            &:disabled {
                cursor: default;
            }
        }
    }
}

.languages-filter {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(3px);

    @media (max-width: 576px) {
        display: block;
    }
}
