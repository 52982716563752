@import 'styles/reset';
@import 'styles/normalize';
@import 'styles/typography';
@import 'styles/variables';

:root {
    --overflow: 'initial';
}

html,
body,
#root {
    width: 100%;
    min-height: 100%;
    overflow: var(--overflow);
}

html,
body {
    -webkit-font-smoothing: antialiased; /* For WebKit browsers */
    -moz-osx-font-smoothing: grayscale; /* For Firefox on MacOS */
}

body {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ffffff;
    }

    &::-webkit-scrollbar {
        width: 10px;
        background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #80b4ff4a;
    }
}

img {
    width: 100%;
    height: auto;
}

.highlight {
    color: #0e800a;
}

button.main-btn {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 14px 24px;
    font-family: 'Paytone One', sans-serif;
    font-size: 14px;
    line-height: 1;
    color: #ffffff;
    text-transform: uppercase;
    background: $btn-gradient;
    box-shadow: $btn-shadow;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in;

    &:disabled,
    &[disabled] {
        opacity: 0.6;
    }

    &:hover:enabled {
        box-shadow: $btn-shadow-hover;
        background:
            url('./assets/btn-hover.svg'),
            linear-gradient(180deg, #e9d906 0%, #e96706 100%) 100%;
        background-size: 100% 100%;
    }
    &:active:enabled {
        box-shadow: $btn-shadow-pressed;
        background:
            url('./assets/btn-hover.svg'),
            linear-gradient(180deg, #e9d906 0%, #e96706 100%) 100%;
        background-size: 100% 100%;
    }

    @media (min-width: $mobile-sm) {
        padding: 22px 30px;
        font-size: 16px;
    }
    @media (min-width: $mobile-lg) {
        font-size: 20px;
    }
    @media (min-width: $tablet-sm) {
        padding: 25px 40px;
        border-radius: 20px;
    }
    @media (min-width: $desktop-sm) {
        padding: 30px 60px;
        font-size: 26px;
    }
    @media (min-width: $desktop-lg) {
        padding: 30px 80px;
        font-size: 40px;
    }

    img {
        width: 15px;
        height: auto;
        margin-left: 7px;

        @media (min-width: $mobile) {
            margin-left: 15px;
        }
        @media (min-width: $tablet-sm) {
            margin-top: 2px;
        }
        @media (min-width: $desktop-lg) {
            width: 19px;
            margin-top: 5px;
        }
    }
}

.wrapper {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;

    @media (min-width: $tablet-sm) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @media (min-width: 1366px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.container {
    max-width: none;

    @media (min-width: 1366px) {
        max-width: 1240px;
        margin: 0 auto;
    }
    @media (min-width: 1536px) {
        max-width: 1460px;
        margin: 0 auto;
    }
}

.action {
    text-align: center;
    margin-bottom: 42px;

    @media (min-width: $tablet-sm) {
        margin-bottom: 60px;
    }
    @media (min-width: $desktop-lg) {
        margin-bottom: 96px;
    }

    &_title {
        padding: 8px 0 24px;

        @media (min-width: $mobile) {
            padding: 18px 0 30px;
        }
        @media (min-width: $mobile-lg) {
            padding: 28px 0 30px;
        }
        @media (min-width: $tablet-sm) {
            padding: 48px 0 32px;
        }
        @media (min-width: $desktop-sm) {
            max-width: 705px;
            margin-right: auto;
            margin-left: auto;
        }
        @media (min-width: $desktop-lg) {
            max-width: none;
            padding: 64px 0 50px;
        }
    }

    button {
        margin: 0 auto;
    }
}
