@import '../../../../styles/variables';

.footer {
    font-family: 'Andika', sans-serif;
    text-align: center;
    line-height: 120%;

    @media (min-width: $mobile-lg) {
        font-size: 18px;
    }

    &_top-text {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 24px;
        line-height: normal;
        background: rgba(128, 180, 255, 0.29);

        @media (min-width: $mobile-lg) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @media (min-width: $tablet-sm) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
        @media (min-width: $desktop-lg) {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        p {
            max-width: 1320px;
            margin: 0 auto;

            @media (max-width: $mobile-lg) {
                font-size: 18px;
            }
        }
    }

    &_main {
        padding-top: 70px;
        padding-bottom: 40px;
        color: #fff;
        background: #011e5c;

        @media (min-width: $mobile) {
            padding-top: 74px;
            padding-bottom: 80px;
        }
        @media (min-width: $tablet-sm) {
            padding-bottom: 46px;
        }
        @media (min-width: $desktop-sm) {
            padding-bottom: 54px;
        }
    }

    &_main-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;

        @media (min-width: $desktop-sm) {
            flex-direction: row;
        }
    }

    &_about {
        text-align: left;

        @media (min-width: $desktop-sm) {
            width: 325px;
        }
        @media (min-width: 1200px) {
            width: initial;
        }

        &__title {
            margin-bottom: 21px;
        }

        &__text {
            @media (min-width: $desktop-sm) {
                max-width: 286px;
            }
            @media (min-width: 1200px) {
                max-width: 360px;
            }
            @media (min-width: $desktop-lg) {
                max-width: 435px;
            }
        }

        &__highlight {
            color: #fc0;
        }
    }

    &_terms {
        display: flex;
        flex-direction: column;

        a {
            color: #fff;
        }

        @media (min-width: $tablet-sm) {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
        }
        @media (min-width: $desktop-sm) {
            align-items: center;
            flex-grow: 1;
        }
    }

    &_logo {
        width: 105px;
        height: 83px;
        margin: 13px auto 34px;

        @media (min-width: $mobile-lg) {
            margin: 16px auto 24px;
        }
        @media (min-width: $tablet-sm) {
            margin: 45px 0 0;
        }
        @media (min-width: $desktop-sm) {
            width: 113px;
            height: 90px;
            margin: 10px auto 0;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &_terms-title {
        letter-spacing: 0.54px;
        text-transform: uppercase;
    }

    &_terms-links {
        margin-top: 14px;

        @media (min-width: $tablet-sm) {
            margin-bottom: 15px;
        }
        @media (min-width: $desktop-sm) {
            margin-top: 18px;
        }

        span {
            text-decoration: underline;
            cursor: pointer;
            transition: color 0.1s ease-in;

            &:hover,
            &:active {
                color: #fc0;
            }
        }
    }

    &_copyright {
        display: flex;
        justify-content: center;
        padding-top: 21px;
        padding-bottom: 21px;
        font-size: 16px;
        color: #88a8eb;
        background: #001747;

        @media (min-width: $mobile) {
            padding-top: 29px;
            padding-bottom: 29px;
        }
        @media (min-width: $mobile-lg) {
            font-size: 18px;
        }

        p {
            max-width: 230px;

            @media (min-width: $mobile) {
                max-width: none;
            }
        }
    }

    .scroll-button{
        position: absolute;
        top: -20px;
        right: 0;
        width: 40px;
        height: 40px;
        z-index: 99;
        cursor: pointer;
    }
}
